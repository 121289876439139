












import LayoutWithPageHeader from "@/components/layout/LayoutWithPageHeader.vue";
import WorkspaceLogo from "@/components/layout/WorkspaceLogo.vue";

import workspaceMixin from "@/mixins/workspace";
import mixins from "vue-typed-mixins";

export default mixins(workspaceMixin).extend({
  components: { LayoutWithPageHeader, WorkspaceLogo },
  props: ["title"],
  computed: {
    companyName(): string {
      return this.ws && this.ws.company ? this.ws.company.name : "";
    },
    companyLogo(): string | null {
      return this.ws && this.ws.company && this.ws.company.logoUrl
        ? this.ws.company.logoUrl
        : null;
    }
  }
});
