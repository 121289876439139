import mixins from "vue-typed-mixins";
import { PropType } from "vue";
import { Bar, mixins as chartjsMixins } from "vue-chartjs";
import { ChartOptions } from "chart.js";
const { reactiveProp } = chartjsMixins;

export default mixins(reactiveProp).extend({
  extends: Bar,
  props: {
    otherOptions: {
      type: Object as PropType<ChartOptions>
    }
  },
  watch: {
    otherOptions: {
      handler(): void {
        this.$data._chart.destroy();
        // @ts-ignore
        this.renderChart(this.chartData, this.options);
      },
      deep: true
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        ...(this.otherOptions as ChartOptions)
      } as ChartOptions
    };
  },
  mounted() {
    // @ts-ignore
    this.renderChart(this.chartData, this.options);
  }
});
