


































































import LayoutWithWsLogo from "@/components/layout/LayoutWithWsLogo.vue";
import UsageDetails from "@/components/workspace/UsageDetails.vue";
import { mapState } from "vuex";
import { ActionTypes } from "@/store/action-types";
import {
  CurrentCreditsUsage,
  OrganizationWorkspace,
  PastCreditsUsage,
  UsageHistory
} from "@/generated/graphql";
import { MonthYear } from "@/models/workspace";
import mixins from "vue-typed-mixins";
import workspaceMixin from "@/mixins/workspace";
import { toEnhancedPlan } from "@/models/organization";

export default mixins(workspaceMixin).extend({
  components: { LayoutWithWsLogo, UsageDetails },
  computed: {
    subscriptionUsage() {
      const ws = this.ws as OrganizationWorkspace | null;
      return ws && ws.subscription ? ws.subscription.usage : null;
    },
    ...mapState({
      usage: state => state.workspace.currentWorkspaceUsage,
      currentSubscriptionUsage: state =>
        state.workspace.currentWorkspaceSubscription?.usage || null,
      plan: state =>
        state.workspace.currentWorkspaceSubscription?.plan
          ? toEnhancedPlan(state.workspace.currentWorkspaceSubscription?.plan)
          : null,
      creditUsageLimit: state =>
        state.workspace.currentWorkspaceSubscription?.settings
          .creditUsageLimit || null
    }),
    wsId(): string {
      return this.$route.params.id;
    },
    currentUsage(): CurrentCreditsUsage | null {
      return this?.usage?.current || null;
    },
    currentHistory(): UsageHistory[] | null {
      return this.usage ? (this.usage.history as UsageHistory[]) : null;
    },
    pastByMonthYear(): (monthYear: MonthYear) => PastCreditsUsage | null {
      return this.$storeTyped.getters.getPastCreditUsageByMonthYear;
    }
  },
  data() {
    return {
      today: new Date(),
      fields: [
        { key: "details", label: "Details" },
        { key: "period", label: "Period" },
        { key: "plan", label: "Plan" },
        { key: "usedCredits", label: "Used credits" },
        { key: "amount", label: "Amount" },
        { key: "status", label: "Status" }
      ],
      changeable: localStorage.changeable,
      expandedRows: {} as { [key: string]: boolean }
    };
  },
  mounted() {
    if (localStorage.changeable) {
      this.changeable = localStorage.changeable;
      this.$root.$emit("changeable", localStorage.getItem("changeable"));
    }
  },
  watch: {
    wsKey: {
      handler(id: string) {
        this.$storeTyped.dispatch(
          ActionTypes.FETCH_CURRENT_WORKSPACE_USAGE,
          this.wsId
        );
        this.$storeTyped.dispatch(
          ActionTypes.FETCH_CURRENT_WORKSPACE_SUBSCRIPTION,
          id
        );
      },
      immediate: true
    }
  },
  methods: {
    toggleRowDetails({ month, year }: UsageHistory) {
      const monthYearStr = `${month}-${year}`;
      this.expandedRows = {
        ...this.expandedRows,
        [monthYearStr]: !this.expandedRows[monthYearStr]
      };
      this.$storeTyped.dispatch(
        ActionTypes.FETCH_CURRENT_WORKSPACE_PAST_CREDIT_USAGE,
        { id: this.wsId, monthYear: { month, year } }
      );
    },
    getPastByMonthYear({ month, year }: UsageHistory): PastCreditsUsage | null {
      return this.pastByMonthYear({ month, year });
    }
  }
});
