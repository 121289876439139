import { Status } from "./../generated/graphql";
import { ActionTypes } from "./../store/action-types";
import Vue from "vue";

/**
 * Mixin to normalize workspace fetching
 *
 */

const workspaceMixin = Vue.extend({
  data() {
    return {
      Status
    };
  },
  computed: {
    ws() {
      return this.$storeTyped.state.workspace.currentWorkspace;
    },
    wsKey() {
      return this.$route.params.id;
    },
    isWsStopped(): boolean {
      return !!this.ws && this.ws.status !== Status.Active;
    }
  },
  watch: {
    $route: {
      handler() {
        this.$storeTyped.dispatch(
          ActionTypes.FETCH_CURRENT_WORKSPACE,
          this.wsKey
        );
      },
      immediate: true
    },
    wsKey() {
      this.$storeTyped.dispatch(ActionTypes.RESET_CURRENT_WORKSPACE);
    }
  }
});

export default workspaceMixin;
