
















import Vue, { PropType } from "vue";
import MixedChart from "../chart/MixedChart";
import { addDays, differenceInCalendarDays, format } from "date-fns";
import {
  CurrentCreditsUsage,
  PastCreditsUsage,
  Plot
} from "@/generated/graphql";
import { ChartDataSets, ChartOptions, ChartXAxe, ChartYAxe } from "chart.js";

export default Vue.extend({
  props: {
    usage: {
      type: Object as PropType<PastCreditsUsage | CurrentCreditsUsage>,
      required: true
    },
    creditLimit: { type: Number, default: null },
    creditUsageLimit: { type: Number, default: null },
    simple: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.daily) {
      this.fillData();
    }
    if (this.options.scales) {
      this.options.scales.xAxes = [
        {
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 6
          }
        }
      ] as ChartXAxe[];

      this.options.scales.yAxes = [
        {
          ticks: {
            maxTicksLimit: this.simple ? 5 : 10,
            suggestedMin: 0,
            suggestedMax: Math.max(this.creditUsageLimit, this.creditLimit, 0),
            callback: function(value: number) {
              return value >= 1000
                ? Math.round((value / 1000 + Number.EPSILON) * 100) / 100 + "K"
                : Math.round((value + Number.EPSILON) * 100) / 100;
            }
          }
        }
      ];
    }
  },
  components: {
    MixedChart
  },
  watch: {
    daily() {
      this.fillData();
    },
    cumulated() {
      this.fillData();
    },
    trend() {
      this.fillData();
    },
    creditLimit() {
      this.fillData();
    },
    creditUsageLimit() {
      this.fillData();
    }
  },
  computed: {
    startDate(): Date {
      return new Date(this.usage.periodStart * 1000);
    },
    endDate(): Date {
      return new Date(this.usage.periodEnd * 1000);
    },
    nbOfDays(): number {
      return differenceInCalendarDays(this.endDate, this.startDate);
    },
    getLabels(): string[] {
      return Array.from(new Array(this.nbOfDays), (_, i) =>
        format(addDays(this.startDate, i), "MMM dd")
      );
    },
    daily(): Plot[] {
      return this.fillMonth((this.usage?.daily || []) as Plot[]);
    },
    cumulated(): Plot[] {
      return this.fillMonth((this.usage?.cumulated || []) as Plot[]);
    },
    trend(): Plot[] {
      return this.fillMonth(
        this.usage && "trend" in this.usage ? (this.usage.trend as Plot[]) : []
      );
    },
    mainGraphs(): ChartDataSets[] {
      return [
        {
          type: "bar",
          label: "Per day consumption",
          data: this.daily.map(d => d.value),
          backgroundColor: "#6400d18c",
          borderSkipped: "top"
        },
        {
          type: "line",
          label: "Mean",
          data: this.cumulated.map(d => d.value),
          backgroundColor: "#e8d5f2",
          borderColor: "#6400d1",
          pointRadius: 0
        }
      ];
    },
    extraGraphs(): ChartDataSets[] {
      return [
        {
          type: "line",
          label: "Trend",
          data: this.trend.map(d => d.value),
          borderDash: [10, 5],
          borderColor: "#ff813c",
          fill: false,
          pointRadius: 0
        },
        {
          type: "line",
          label: "Credit limit",
          data: Array.from(new Array(this.nbOfDays), () => this.creditLimit),
          borderDash: [10, 5],
          borderColor: "#099220",
          fill: false,
          pointRadius: 0
        },
        {
          type: "line",
          label: "Credit usage limit",
          data: Array.from(
            new Array(this.nbOfDays),
            () => this.creditUsageLimit
          ),
          borderDash: [10, 5],
          borderColor: "#f31246",
          fill: false,
          pointRadius: 0
        }
      ];
    }
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: this.getLabels,
        datasets: this.simple
          ? this.mainGraphs
          : [...this.mainGraphs, ...this.extraGraphs]
      };
    },
    fillMonth(plots: Plot[]): Plot[] {
      const plotDict = plots.reduce(
        (acc, val) => ({ ...acc, [val.name || ""]: val }),
        {}
      ) as { [key: string]: Plot };

      return Array.from(new Array(this.nbOfDays), (_, i) => {
        const dayNb = `${addDays(this.startDate, i).getDate()}`;
        return plotDict[dayNb]
          ? plotDict[dayNb]
          : ({ name: dayNb, value: null } as Plot);
      });
    }
  },
  data() {
    return {
      datacollection: {},
      options: {
        scales: {
          xAxes: [] as ChartXAxe[],
          yAxes: [] as ChartYAxe[]
        },
        legend: {
          display: false
        },
        layout: {
          padding: {
            top: 12
          }
        },
        tooltips: {
          enabled: true,
          intersect: false
        }
      } as ChartOptions
    };
  }
});
