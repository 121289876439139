















































import Vue, { PropType } from "vue";
import UsageTable from "@/components/workspace/UsageTable.vue";
import UsageGraph from "@/components/workspace/UsageGraph.vue";
import {
  CurrentCreditsUsage,
  PastCreditsUsage,
  SubscriptionUsage
} from "@/generated/graphql";
import { EnhancedPlan } from "@/models/organization";

export default Vue.extend({
  components: { UsageTable, UsageGraph },
  props: {
    usage: {
      type: Object as PropType<PastCreditsUsage | CurrentCreditsUsage>,
      required: true
    },
    plan: {
      type: Object as PropType<EnhancedPlan>
    },
    subscriptionUsage: {
      type: Object as PropType<SubscriptionUsage>
    },
    creditUsageLimit: {
      type: Number
    },
    simple: {
      type: Boolean,
      default: false
    },
    year: {
      type: Number
    },
    month: {
      type: Number
    },
    isWsStopped: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    creditLimit(): number | null {
      return this.plan?.flatLimit || null;
    }
  }
});
