
















import Vue from "vue";
import PageHeader from "@/components/layout/PageHeader.vue";

export default Vue.extend({
  components: { PageHeader },
  props: {
    title: String,
    pretitle: String,
    noGutter: {
      type: Boolean,
      default: false
    }
  }
});
