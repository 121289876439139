

















import Vue from "vue";
export default Vue.extend({
  name: "PageHeader",
  props: {
    pretitle: {
      type: String
    },
    title: {
      type: String,
      default: ""
    },
    noGutter: {
      type: Boolean,
      default: false
    }
  }
});
