



































































import { CreditTypeCounter, UsageCounter } from "@/generated/graphql";
import { RouteName } from "@/router";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    isForecast: {
      type: Boolean,
      default: false
    },
    isWsStopped: {
      type: Boolean,
      default: false
    },
    title: String,
    totalCredit: { type: Number, default: 10 },
    totalCost: { type: Number, default: 10 },
    creditCounter: {
      type: Array as PropType<CreditTypeCounter[]>
    },
    credit: {
      type: Object as PropType<UsageCounter>,
      default: () => ({
        amount: "-",
        count: "-",
        currency: "--"
      })
    },
    noCredit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      workspacePlanRoute: RouteName.WORKSPACE_PLAN
    };
  },
  computed: {
    centeredColor() {
      return this.isForecast
        ? { color: "#ff8100", backgroundColor: "#ffebdd" }
        : { color: "#6400d1", backgroundColor: "#e8d5f2" };
    },
    tableStyle() {
      return this.isForecast ? { color: "#ff8100" } : { color: "#6400d1" };
    }
  }
});
